<template>
  <div>
  <div class="main">
    <van-cell-group class="group">
        <van-cell title="名称" :value="group.clientName" />
        <van-cell title="所属集团" :value="group.gruopClientName" />
        <van-cell title="收款金额" :value="group.collectAmount" />
        <van-cell title="结算金额" :value="group.settleAmount" />
    </van-cell-group>

    <div class="msg">
        <!-- <img class="msg-pho" src="~@/assets/img/qr.png"> -->
        <img class="msg-pho" :src="group.groupImg"/>
        <p class="main-text reds" style="margin-top: 5px;">{{group.gruopClientName}}收款二维码</p>
        <!-- <van-button type="info" color="#eb9c2e" block style="margin:20px 0 35px 0" @click="downloadImg">请长按图片保存</van-button> -->
        <van-button type="info" color="#eb9c2e" block style="margin:20px 0 35px 0" @click="showPopup">请长按图片保存</van-button>

    </div>

    <div >
          <van-field v-model="value" placeholder="请输入MSISDN、ICCID系统将自动检索" />
          <van-button type="info" block style="margin-top:20px" @click="submitMsisdn">查询</van-button>
      </div>
    <p class="main-text reds" style="margin-top: 20px;">请核对本次充值的卡号是否正确，如充值号码不正确请重新输入，充值成功将无法退还。</p>
    <!-- <p class="main-text">温馨提示：系统自动记住您最近查询成功的号码</p> -->
  </div>

  <van-popup v-model="show" round style="width:90%">
      <div style="padding-bottom:20px;">
          <span style="font-size:14px;">请长按图片保存收款二维码</span>
          <img class="msg-phos" :src="group.groupImg"/>
      </div>
  </van-popup>
  
</div>
</template>

<script>
import md5 from 'js-md5';
import codeImg from '@/assets/img/qr.png';
export default {
    name: 'GroupHome',
    data() {
        return {
            group:{
                clientName:'',
                gruopClientName:'',
                collectAmount:'',
                settleAmount:''
            },
            img:codeImg,
            base64:'',
            value:'',
            search:'',
			placeholder:'手动输入13位卡号或20位iccid',
			codetrue:false,
            config:{},
            show:false
        }
    },
    methods:{
        showPopup(){
            this.show = true;
        },
        downloadImg(){
            let link = document.createElement('a')
            link.href = this.group.groupImg
            link.download = `${this.group.gruopClientName}收款二维码`
            link.click()
        },
        toDetails() {
            this.$router.push('/card/info');
        },
        submitMsisdn(){
            let uuid = localStorage.getItem("uuid");
            localStorage.setItem("sign",md5(md5(this.value + uuid) + uuid));
            this.$api.getCardInfo({cardNumber:this.value}).then(res => {
            if(res.data.code == 0){
                if(!!res.data.data && res.data.data.length != 0){
                    localStorage.msisdn = this.value;
                    this.$router.push('/card/info');
                }else{
                    this.$dialog.alert({message: '未查询到卡号，请重新输入'})
                }
            }
            
        })
        }
    },
    created(){
        this.$store.commit('setPageTitle', "集团信息");
        let groupClientUUID = localStorage.getItem("groupClientUUID");
        this.$api.groupInfo({uuid:groupClientUUID}).then(res => {
            if(res.data.code == 0){
                this.group = Object.assign({},res.data.data)
                // this.group.groupImg = `${process.env.VUE_APP_BASE_API}/${this.group.collectQrCode}`
                this.group.groupImg = `${this.group.collectQrCode}`
            }else{
                this.$router.push({name:'GroupLogin'})
            }
            
        })
    }

}
</script>

<style lang="scss" scoped>
p{
    font-size: 30px;
}
.van-cell{
    font-size:0.5rem;
}

.group{
    margin-top: 20px;
    .van-cell{
        font-size:0.3rem;
        text-align: left;
    }
}



.search-box{
	position:relative;
	width:85%;/*604px*/
	margin:2.4rem auto 0;/*180px*/
}

.search-input{
	width:94%;/*498px*/
	height:2rem;/*78px*/
	border:1px solid #1781ef;/*2px*/
	border-radius:0.3rem;/*12px*/
	font-size:0.8rem;/*28px*/
	padding:.2rem 3%;/*0 82px 0 20px*/
}

.code-icon{
	background-image:url('~@/assets/img/code.png');
	background-size:cover;
	width:1.9rem;/*62px*/
	height:1.9rem;/*62px*/
	position:absolute;
	top:0.3rem;/*9px!Warning*/
	right:0.3rem;/*12px*/
}

.search-btn{
	width:100%;/*604px*/
	height:2.6rem;/*83px*/
	border: none;
	background-color:#197fee;
	border-radius:0.3rem;/*12px*/
	font-size:1rem;/*38px*/
	color:#fff;
	display:block;
  margin-top: 1rem;
}

.main-text,
.msg-text{
	text-align:center;
	font-size:20px;/*24px*/
	color:#838383;
}

.msg{
	margin-top: 10px;
	width:100%;
	float:left;
	/* background-color:#f6f6f6; */
	overflow: hidden;
}

.msg-pho{
	width:60%;/*427px*/
	display:block;
	margin:0 auto ;/*60px*/
}
.msg-phos{
    width:80%;
	display:block;
	margin:0 auto ;
}
.reds{
	color: red;width: 80%;margin: 10px auto;
}


</style>
